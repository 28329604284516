import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12d6d494"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "selections-container"
}
const _hoisted_2 = {
  key: 0,
  class: "selection-row"
}
const _hoisted_3 = { class: "selection-text" }
const _hoisted_4 = { class: "selection-remove-btn" }
const _hoisted_5 = { class: "selection-text" }
const _hoisted_6 = { class: "selection-remove-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_multi_select = _resolveComponent("dropdown-autocomplete-multi-select")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs,
    class: "dropdown-autocomplete"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dropdown_autocomplete_multi_select, _mergeProps(_ctx.$props, {
        "model-value": _ctx.modelValue,
        onOnSelect: _ctx.onSelect
      }), null, 16, ["model-value", "onOnSelect"]),
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.modelValue.length > 4)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_b_badge, {
                    class: "selection-badge",
                    variant: "light"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.modelValue.length) + " " + _toDisplayString(_ctx.titleCase(_ctx.$t('core.common.selected'))), 1),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_b_button, {
                          variant: "light",
                          class: "remove-btn",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearSelections()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_faicon, {
                              icon: "times",
                              size: "xs"
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.modelValue, (data) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: data,
                    class: "selection-row"
                  }, [
                    _createVNode(_component_b_badge, {
                      class: "selection-badge",
                      variant: "light"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(data[_ctx.searchBy]), 1),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_b_button, {
                            variant: "light",
                            class: "remove-btn",
                            onClick: ($event: any) => (_ctx.clearSelection(data))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_faicon, {
                                icon: "times",
                                size: "xs"
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}