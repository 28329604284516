import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_base = _resolveComponent("dropdown-autocomplete-base")!

  return (_openBlock(), _createBlock(_component_dropdown_autocomplete_base, _mergeProps(_ctx.$props, {
    "close-on-select": false,
    "model-value": null,
    "highlighted-items": _ctx.highlightedItems,
    "lock-input-on-select": false,
    onOnSelect: _ctx.handleSelect
  }), null, 16, ["highlighted-items", "onOnSelect"]))
}