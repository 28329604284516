
import { defineComponent } from 'vue';
import DropdownAutocompleteMultiSelect from '@/components/dropdown/DropdownAutocompleteMultiSelect.vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useStringFormatter from '@/composable/useStringFormatter';

export default defineComponent({
    name: 'form-multi-select',
    components: {
        DropdownAutocompleteMultiSelect, BButton, BBadge, BCol,
    },
    extends: DropdownAutocompleteMultiSelect,
    props: {},
    emits: ['update:modelValue'],
    setup(props, context) {
        const { titleCase } = useStringFormatter();

        function clearSelections() {
            context.emit('update:modelValue', []);
        }

        function clearSelection(val: Object) {
            // @ts-ignore
            const copy = [...props.modelValue];
            // @ts-ignore
            const newCopy = copy.filter((d) => d[props.searchBy] !== val[props.searchBy]);
            context.emit('update:modelValue', newCopy);
        }

        function onSelect(val: Object) {
            context.emit('update:modelValue', val);
        }

        return {
            onSelect,
            clearSelections,
            clearSelection,
            titleCase,
        };
    },
});
