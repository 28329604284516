import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8256ce02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "autocomplete-input-container" }
const _hoisted_5 = {
  key: 2,
  class: "loading-spinner"
}
const _hoisted_6 = { class: "autocomplete-result-container" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  style: {"margin-left":"15px"},
  class: "nowrap-with-ellipse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_col = _resolveComponent("b-col")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs,
    class: "dropdown-autocomplete"
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        ref: "autocompleteBaseRef",
        class: "autocomplete-container",
        style: _normalizeStyle({ flexDirection: _ctx.state.direction === 'down' ? 'column' : 'column-reverse' })
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_b_form_input, {
            placeholder: !_ctx.state.hasData && !_ctx.disabled ? _ctx.emptyText : _ctx.placeholder,
            type: "text",
            "model-value": _ctx.state.search,
            autocomplete: "off",
            style: {"margin-bottom":"0"},
            disabled: _ctx.inputDisabled,
            autofocus: _ctx.focusOnLoad || _ctx.autofocus,
            onInput: _ctx.onChange,
            onChange: _ctx.onChange,
            onKeyup: _withKeys(_ctx.onEnterKey, ["enter"])
          }, null, 8, ["placeholder", "model-value", "disabled", "autofocus", "onInput", "onChange", "onKeyup"]),
          (!_ctx.isSet && _ctx.state.results.length > 0)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 0,
                disabled: _ctx.inputDisabled,
                class: "button-open-all",
                onClick: _ctx.toggleOpen
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_faicon, {
                    icon: _ctx.state.isOpen ? 'chevron-up' : 'chevron-down'
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isSet && !_ctx.disabled)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 1,
                class: "button-clear",
                onClick: _ctx.clear
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.clear')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.state.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_b_spinner, { small: "" })
              ]))
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          (_ctx.state.results.length > 0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.results, (res, index) => {
                  return (_openBlock(), _createBlock(_component_b_button, {
                    key: index,
                    class: _normalizeClass(['autocomplete-result', { highlight: _ctx.highlight(_ctx.displayValue(res)) }]),
                    variant: "light",
                    onClick: ($event: any) => (_ctx.setResult(res))
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "option", { item: res }, () => [
                        (_ctx.highlight(_ctx.displayValue(res)))
                          ? (_openBlock(), _createBlock(_component_faicon, {
                              key: 0,
                              icon: "check"
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.displayValue(res)), 1)
                      ], true)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, _ctx.state.isOpen]
        ])
      ], 4)
    ]),
    _: 3
  }, 8, ["cols", "lg", "md", "sm", "xs"])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}