
import {
    defineComponent, onMounted, PropType, reactive, watch, onBeforeUnmount, computed,
} from 'vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useScreenDetector from '@/composable/useScreenDetector';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    search: string;
    results: Array<unknown>;
    isOpen: boolean;
    hasData: boolean;
    loading: boolean;
    direction: 'up' | 'down';
};

export default defineComponent({
    name: 'dropdown-autocomplete-base',
    components: {
        BCol,
        BFormInput,
        BButton,
        BSpinner,
    },
    props: {
        data: {
            type: Array as PropType<Array<any>>,
            default: () => [],
        },
        searchBy: { type: String, required: true },
        displayName: { type: String, required: false, default: undefined },
        searchFn: Function as PropType<(search: string) => Promise<Array<any>>>,
        searchDebounceTimeout: { type: Number, default: 500 },
        onBeforeClear: Function as PropType<() => Promise<boolean>>,
        modelValue: String,
        placeholder: {
            type: String,
            default: () => `${getTitleCaseTranslation('core.common.typeToSearch')}...`,
        },
        disabled: Boolean,
        label: {
            type: String,
            default: undefined,
        },
        cols: {
            type: String,
            default: undefined,
        },
        lg: {
            type: String,
            default: undefined,
        },
        md: {
            type: String,
            default: undefined,
        },
        sm: {
            type: String,
            default: undefined,
        },
        xs: {
            type: String,
            default: undefined,
        },
        loading: { type: Boolean, default: false },
        emptyText: { type: String, default: () => getTitleCaseTranslation('core.validation.noResults') },
        error: {
            type: String,
            default: undefined,
        },
        focusOnLoad: { type: Boolean, default: false },
        closeOnSelect: { type: Boolean, default: true },
        highlightedItems: { type: Array as PropType<Array<string>>, default: () => [] },
        lockInputOnSelect: { type: Boolean, default: true },
        selectOnEnter: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
    },
    emits: ['onSelect', 'onClear'],
    setup(props, context) {
        const state = reactive<State>({
            search: '',
            results: [],
            isOpen: false,
            hasData: false,
            loading: props.loading,
            direction: 'down',
        });
        const isSet = computed((): boolean => !!props.modelValue);
        const inputDisabled = computed((): boolean => props.disabled || isSet.value || !state.hasData);
        let searchTimeout: number | undefined;

        onBeforeUnmount(() => {
            clearTimeout(searchTimeout);
        });

        const { element: autocompleteBaseRef, getPosition } = useScreenDetector();

        function displayValue(result: any) {
            if (props.displayName && props.displayName !== props.searchBy) {
                return `${result[props.displayName]} (${result[props.searchBy]})`;
            }
            return result[props.searchBy];
        }

        function setInitialValue() {
            if (!props.modelValue || !state.hasData) {
                return;
            }
            if (props.searchFn) {
                state.search = props.modelValue;
            } else {
                const match = props.data.find((data) => data[props.searchBy] === props.modelValue);
                if (match) {
                    state.search = displayValue(match);
                }
            }
        }

        onMounted(() => {
            state.isOpen = false;
            state.results = props.searchFn ? [] : props.data;
            if (props.searchFn || props.data.length) {
                state.hasData = true;
            }
            setInitialValue();
        });

        watch(
            () => props.disabled,
            (newVal: boolean, oldVal?: boolean) => {
                if (!oldVal && newVal && props.lockInputOnSelect) {
                    state.search = props.modelValue ? props.modelValue : '';
                }
            },
        );

        watch(
            () => props.loading,
            (newVal: boolean, oldVal?: boolean) => {
                if (!newVal && oldVal && props.lockInputOnSelect) {
                    state.loading = newVal;
                    state.search = props.modelValue ? props.modelValue : '';
                    if (props.searchFn) {
                        state.hasData = true;
                    } else {
                        state.hasData = props.data.length > 0;
                        state.results = props.data;
                    }
                    setInitialValue();
                }
            },
            { immediate: true },
        );

        watch(() => props.modelValue, (newVal, oldVal) => {
            if (newVal && !oldVal && !props.searchFn) {
                state.search = newVal;
            }
            if (!newVal && oldVal) {
                state.search = '';
                state.results = props.data;
            }
        });

        function toggleOpen() {
            state.direction = getPosition() === 'top' ? 'down' : 'up';
            state.isOpen = !state.isOpen;
        }

        async function doSearch() {
            if (props.searchFn) {
                state.loading = true;
                state.results = await props.searchFn(state.search.trim());
                state.loading = false;
            } else {
                state.results = props.data;
            }
        }

        async function onChange(val: string) {
            state.search = val;
            if (state.search.trim().length > 0) {
                if (props.searchFn) {
                    clearTimeout(searchTimeout);
                    // debounce the search to minimize requests to the server
                    searchTimeout = setTimeout(doSearch, props.searchDebounceTimeout);
                } else {
                    const lowerSearch = state.search.toLowerCase();
                    state.results = props.data.filter((d: any) => {
                        let match = false;
                        if (props.displayName && props.displayName !== props.searchBy) {
                            // if these props are different we need to search in both fields
                            match = d[props.displayName]?.toLowerCase().includes(lowerSearch);
                        }
                        return match || d[props.searchBy]?.toLowerCase().includes(lowerSearch);
                    });
                }
                if (!state.isOpen) toggleOpen();
            } else {
                state.results = props.searchFn ? [] : props.data;
                if (props.closeOnSelect) state.isOpen = false;
            }
        }

        async function clear() {
            if (!props.disabled) {
                if (isSet.value && props.onBeforeClear) {
                    const response = await props.onBeforeClear();
                    if (response) {
                        context.emit('onSelect', null);
                        state.search = '';
                    }
                } else {
                    context.emit('onSelect', null);
                    state.search = '';
                }
                state.results = props.searchFn ? [] : props.data;
                context.emit('onClear');
            }
        }

        function setResult(result: any) {
            context.emit('onSelect', result);
            if (props.lockInputOnSelect) {
                state.search = displayValue(result);
            }
            if (props.closeOnSelect) state.isOpen = false;
        }

        function handleClickOutside() {
            state.isOpen = false;
        }

        function highlight(value: string): boolean {
            return !!props.highlightedItems?.includes(value);
        }

        function onEnterKey() {
            if (props.selectOnEnter && state.results.length > 0) {
                setResult(state.results[0]);
            }
        }

        return {
            state,
            onChange,
            setResult,
            toggleOpen,
            clear,
            handleClickOutside,
            inputDisabled,
            isSet,
            highlight,
            autocompleteBaseRef,
            getTitleCaseTranslation,
            displayValue,
            onEnterKey,
        };
    },
});
