
import { computed, defineComponent, PropType } from 'vue';
import DropdownAutocompleteBase from '@/components/dropdown/DropdownAutocompleteBase.vue';

export default defineComponent({
    name: 'dropdown-autocomplete-multi-select',
    components: { DropdownAutocompleteBase },
    extends: DropdownAutocompleteBase,
    emits: ['update:modelValue', 'onSelect'],
    props: {
        modelValue: {
            type: Array as PropType<Array<unknown>>,
        },
    },
    setup(props, context) {
        const highlightedItems = computed(() =>
            // @ts-ignore
            props.modelValue?.map((d) => d[props.searchBy]));

        function handleSelect(value: unknown) {
            if (value) {
                // @ts-ignore
                const modelValueCopy = [...props.modelValue];
                // @ts-ignore
                const index = modelValueCopy.findIndex((d) => d[props.searchBy] === value[props.searchBy]);
                if (index > -1) {
                    modelValueCopy.splice(index, 1);
                } else {
                    // @ts-ignore
                    modelValueCopy.unshift(value);
                }
                context.emit('update:modelValue', modelValueCopy);
                context.emit('onSelect', modelValueCopy);
            }
        }

        return {
            handleSelect,
            highlightedItems,
        };
    },
});

